.DashboardProfileSetting{
    display: flex;
    flex-direction: column;
    gap:16px;
    color:rgb(73,80,87)
}

.profile-logo{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding:16px;
    
}

.logo-container{
    background-color: #F5F5F8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width:60px;
    height: 60px;
    font-size: 30px;
   

}
.banking-details{
margin: 0 auto;
width:auto;
display: flex;
flex-direction: column;
gap:12px;
}
.notverified-status{
    background-color: #CF5A5A;
    padding:8px;
    color:white;
    display: inline-block;
    border-radius: 8px;
    width: 150px;
    text-align: center;

}
.verified-status{
    background-color: #107836;
    padding:8px;
    color:white;
    display: inline-block;
    border-radius: 8px;
    width: 150px;
    text-align: center;
}

.profile-details, .account-limits{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding:16px;
}
.profile-details >p, .account-limits>p{
   color: #007CC5;
}
.profile-details>div, .account-limits>div{
    display: flex;
    gap: 12px;
    padding: 16px 0;
    border-bottom: 1px solid #F5F5F8;
}

.profile-detail-label{
    width: 100px;
}
.account-limits-label{
    width:200px;
}

