
  


.about-us-wrapper{
    display: flex;
    justify-content: space-evenly;
    padding:50px 0;
    width: 80%;
    margin:0 auto;
}
.about-us-wrapper>div{ 
   width :50%;
   padding:30px;
   
}
.about-us-wrapper>.right{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.about-us-wrapper>.left>img{
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 25px;
    padding: 20px 0;
}
.why-choose-us-container{
    background-color: var(--backgroundLight);
    padding:60px 0;
}
.why-choose-us-wrapper{
    margin: 0 auto;
    width:80%;
    
}
.why-choose-us-wrapper>h1,.why-choose-us-wrapper>p{
   text-align: center;
   padding-bottom: 10px;
}
.card-container{
    display: flex;
    flex-wrap: wrap;
}
.card-container>div{
   width: calc( 100% / 3);
}

.ourservices-cards{
    width:80%;
    margin:0 auto;
    display: flex;
}
.ourservices-cards>div{
    width:50%;
   padding: 15px;

}
.our-journey{
    color:white;
    padding:60px 0;
    background: linear-gradient(to right, var(--red-500) 0%, var(--red-600) 100%);
}

.our-journey>div>h1{
    color:white;
}

.our-journey-description{
    width: 60%;
   margin:0 auto;
}

.count-cards-container{
    display: flex;
}
.count-cards-container>div{
   width : calc(100% / 4);
   padding:10px;
}

.our-history-container{
    padding:60px 0;
}


.our-history-wrapper{
    margin: 0 auto;
    width:80%;
    display: flex;
}
.our-history-wrapper>div{
   width:50%;
   padding:20px;

}
.our-history-wrapper>.left{
    padding:25px;
}
.our-history-wrapper>.left>p{
 color: var(--textColor1);
}
.our-history-wrapper>.right{
  display: flex;
  align-items: center;
 
 
}

.our-history-wrapper>.right>video{
   width:100%;
}

.our-process-container{ 
    padding:60px 0;
    background-color: var(--backgroundLight);
}

.our-process-wraper{
    margin: 0 auto;
    width:80%;
    display: flex;
    flex-direction: column;
    gap:20px;

}

.process-header{
    text-align: center;
    ;
}
.processes-container{
   width: 100%;
   padding: 30px;
   gap:10px;
    display: flex;
}
.processes-container>div{
  width:calc(100%/ 4)
}

.client-reviews{
    padding:60px 0;
}
.client-reviews-wrapper{
    margin: 0 auto;
    width:80%;
    text-align: center;
}

.review-container{
   display: flex;
}
.review-container>div{
   width:calc(100% / 3);
  
   padding: 10px;
}

.FAQs-container{
    padding:60px 0;
    background-color: var(--backgroundLight);
  

}

.FAQ-wrapper{
    margin: 0 auto;
    width:80%;
}

.Get-Started-container{
    padding:60px 0; 
}
.get-started-wrapper{
    margin: 0 auto;
    width:80%;
    display: flex;
}
.get-started-wrapper>div{
   width: 50%;
   padding:10px;
}

.form-holder{
    padding: 40px 30px;
    background-color: var(--backgroundLight);
    margin:auto;
    width:80%;

}

.get-started-wrapper>.right{
    display: flex;
    flex-direction: column;
    gap:10px;
}

.btn-get-solution{
   
    border-radius: 4px;
    padding: 10px 18px;
    text-align: center;
    color:  white;
}

.btn-get-solution:hover{
    background-color:rgb(21,39,116);
    color: white;
}

    @media screen and (max-width: 768px) {
        .about-us-wrapper{
            flex-direction: column-reverse;
            width: 100%;
            padding:10px
        }
        .about-us-wrapper>div{
           width: 100%;
        }
        .why-choose-us-wrapper{
           
            width: 80%;

            
        }
        .card-container{
           flex-direction: column;
           margin: 0 auto;
        }
        .card-container>div{
           width: 80%;
           margin: 0 auto;
        }
        .ourservices-cards{
            width:100%;
            margin:0 auto;
            display: block;
        }
        .ourservices-cards>div{
            width:100%;
           padding: 15px;
        
        }
        .count-cards-container{
            display: block;
        }
        .count-cards-container>div{
           width :100%;
           padding:10px;
        }

       
        .our-journey-description{
            width: 90%;

        }
        
        .our-history-wrapper{
            margin: 0 auto;
            width:100%;
            display: block;
        }
        .our-history-wrapper>div{
           width:100%;
           padding:15px;
        
        }
        .our-history-wrapper>.right{
          position: relative;;

          height: 400px;
        
        }
        .youtube-video {
            position: absolute;
            left:0;
            top:0;
            width: 100%;
            height: 100%;
          }
        
        .our-process-wraper{
           
            width:100%;
            display: block;
           
           
        
        }
        
       
        .processes-container{
            display: flex;
            flex-direction: column;
           width: 90%;
           padding: 15px;
           gap:15px;
            margin:0 auto;
        }
        .processes-container>div{
          width:100%;
        }
        
        .client-reviews-wrapper{
            margin: 0 auto;
            width:90%;
            text-align: center;
        }
        
        .review-container{
           display: block;
        }
        .review-container>div{
          width:100%;
          
           padding: 10px;
        }
        
        .get-started-wrapper{
            margin: 0 auto;
            width:90%;
            display: block;
        }
        .get-started-wrapper>div{
           width: 100%;
           padding:15px;
        }
        .form-holder{
            
           
            width:90%;
        
        }
    }