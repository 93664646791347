@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Rowdies:wght@300;400;700&display=swap');


*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Outfit';
   
}

.google_translate_element{
    padding: 0px;
    
}
:root {
    --textColor1: #707070; 
    
    --backgroundLight:#F6F6F6;

    --red-50: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;
  --red-950: #450a0a;

}
.PageHeader{
    font-family: 'Rowdies';
        font-size: 30px;
        text-align: center;
        padding: 20px 0;
}

.Theader{
    color: var(--red-700);
        font-family: 'Rowdies';
        font-size: 25px;
} 
.TDes{
    font-size: 20px;
    color: var(--red-500);
}  


a{
    color:white;
    text-decoration: none;
}


form{
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 100%;
}


form>button{
    padding:10px;
    background-color: var(--red-300);
    text-align: center;
    color: var(--red-950);
    border-radius: 20px;
}
form>button:hover{
    padding:10px;
    background-color: var(--red-500);
    text-align: center;
    color:white;
    border-radius: 20px;
}
input, textarea, select{
    outline:none;
    border: 1px solid var(--red-100);
    padding: 10px;
    border-radius: 12px;
    width: 100%;
}

button{
    padding:10px;
    background-color: var(--red-300);
    text-align: center;
    color:var(--red-900);
    border-radius: 20px;
}
button:hover{
    padding:10px;
    background-color: var(--red-600);
    text-align: center;
    color:white;
    border-radius: 20px;
}

.form-error-container {
    width: 80%;
    margin: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid darkred;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
  
  }
  
  .form-error-container>p {
    margin: auto;
    text-align: center;
  }
  
  .form-sucess-container{
    width: 80%;
    margin: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid darkgreen;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .form-sucess-container>p{
    margin: auto;
    text-align: center;
    color: green;
  }
.choice-lable{
    display:  flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: 10px;
}


@media screen and (max-width: 768px) {
    .Theader{
        font-family: 'Rowdies';
        font-size: 20px;
        text-align: center;
} 
.TDes{
    font-size: 18px;
} 
}