.count-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    border: 1px dashed white;
    border-radius: 5px;
    padding:10px;
}

.counticon
{
    font-size: 45px;
}
.couterup{
    font-size: 30px;
}

.counter-description{
    font-size: 20px;
}