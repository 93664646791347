.DashboardBankTransfers{
    color:rgb(73,80,87);
    padding:16px;
    background-color:white;
}

.form-field{
    display: flex;
    gap:10px;

}
.form-field>label{
    width: 200px;
}
.error-container{
    background-color:bisque;
    color: red;
    border-radius: 13px;
    padding: 30px;
    margin-bottom: 20px;
}
.error-container>ul{
    
    padding-left: 30px;
}
.error-item{
    font-size: 12px;
}
.error-lable{
    font-weight: bold;
}

.billinformat{
    display: flex;
    flex-direction: column;
   
   
    
}
.billingInfoComponent>p{
    font-size: 18px;
    text-align: center;
  
    
}
.billingInfoComponent>h1{
    font-size: 20px;
    font-weight: bold;
    color : #007CC5;
    text-align: center;
    
}
.billingformaticon{
    font-size: 55px;
    color:rgb(224, 95, 95);
    text-align: center;
    font-weight: bold;
}

.withdrawal-wrapper{

    margin-bottom: 30px;
    background-color: rgb(245, 205, 205);
    padding: 10px;
    border-radius: 18px;
}

.widrawal-header{
    display: flex;
    gap:10px;
    align-items: center;
}
.widrawal-header>h1{
   font-size: 25px;
   font-weight: bold;
   color:#007CC5;
}
.widrawal-header>i{
  font-size: 12px;
}


.withdral-detail-container{
    padding: 20px;
    display: flex;
    flex-direction: column ;
    gap: 8px;


}
.withdrawalDetail>{
 color: var(--textColor1);
 font-size: 20px;
}
.withdrawalDetail>span{
 font-weight: bold;
}

.Deduction-Summary{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Deduction-Summary>h1{
    font-weight: bold;
    font-size: 30px;
    color:rgb(231, 72, 72);
    text-align: right;
}


@media screen and (max-width: 768px) {
    .form-field{
        flex-direction: column;
    
    }
    .widrawal-header>h1{
        font-size: 20px;
        
     }
}