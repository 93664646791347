.toswrapper{
    width: 100%;
    color:  #707070;
   
    font-size: 18px;
    padding: 2rem 0;
    text-align: justify;
}
.toscontainer{
    width: 80%;
    margin: auto;
}
.toscontainer>h1{
    font-size: 35px;
    padding: 2rem 0;
    text-align: center;
    color: #007CC5;
    
}

.tos-body>p{
 padding: 15px 0;
}
.tos-body>h3{
    font-size: 20px;
    padding: 1rem 0;
    color: white;
    text-shadow: var(--textShadow);
}