.card-component3{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:8px;
    align-items: center;
}
.card-component3>.top{
    width: 100%;
    position: relative;
    background-color: var(--red-400);
    width:120px;
    height: 120px;
    border-radius: 50%;
    position: relative;
}
.card-icon{
    font-size: 40px;
    color:white;
}

.step-number{
    position:absolute;
    width:45px;
    height: 45px;
    background-color: var(--red-300);
    top:-10px;
    right: 10px;
    color: var(--red-950);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.card-description{
    text-align: center;
    color: var(--textColor1);
}




