.admin-dashboard{
    padding: 60px 0;
}


.admin-dashboard-wrapper{
   max-width:700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.search-div{
    display: flex;
    gap: 10px;
    align-items: center;
}

.search-button{
    font-size: 25px;
    font-weight: 30px;
}
.search-button:active {
    color: darkcyan;
}

.user-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
   
}
.user-container>div{
   border:1px solid lightblue;
   padding:15px 10px;
   display: flex;
   flex-direction: column;
   gap:10px;
}

.user-controls-buttons{
    display: flex;
    gap:12px;
    flex-wrap: wrap;
}
.user-controls-buttons>button{
   padding: 2px 12px;
   border-radius: 20px;
}
.user-controls-buttons>button:active{
   background-color: midnightblue;

}

.disabled-button{
    background-color: gray;
}

.funds-container{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.funds-container>input{
    padding: 5px;
    font-size: 12px;
    width: 200px;
}
.funds-container>div{
    display: flex;
    gap: 5px;
}
.funds-container>div>button{
    padding: 2px 12px;
    border-radius: 20px;
}
.funds-container>div>button>div{
    background-color: midnightblue;
}

@media screen and (max-width: 768px) {
    .funds-container>input{
        padding: 5px;
        font-size: 12px;
        width: 100%;
    }
    .admin-dashboard-wrapper{
        width:100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .search-div{
        width: 80%;
        margin: 0 auto;
    }
}