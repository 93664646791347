.contact-cards-container{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin:0 auto;
    padding:60px 0;
}
.contact-cards-container>div{
    width: calc(100%/5);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1); /* Add slight box shadow */
    border-radius: 10px;
    height: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:5px;
}
.contact-cards-container>div>i{
   font-size: 30px;
   color: #007CC5;
}
.contact-cards-container>div:hover {
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2); /* Box shadow on hover */
  }

  .card-info{
    color: #6C757D;
  }
  @media screen and (max-width: 768px) {
    .contact-cards-container{
       flex-direction: column;
      
       gap:12px;
       align-items: center;
    }
    .contact-cards-container>div{
       width: 80%;
    }
  }