.Dashboard{
    display: flex;
    justify-content: space-between;
   
}

.DashboardMenu{
   background-color: var(--red-950);
    color:var(--red-300);
    padding:15px;

}
.DashboardMenu>ul{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin:35px 0;
    justify-content: center;
}
.DashboardMenu>ul>li{
   padding: 0 10px;
   display: flex;
   gap:15px;
   align-items: center;
}
.DashboardMenu>ul>li>i{
   font-size: 18px;
   width:10%;
  
}
.DashboardMenu>ul>li>p{
   font-size: 18px;
   text-align: left;
}

.selected {
  color: white;
}
.DashboardMenu-Tab{

background-color: #F6F6F9;
flex: 1;
padding: 16px;
min-height: 800px;

}

.menu-toggle{
   display: flex;
   gap:20px;
   align-items: center;
   padding: 25px;
   background-color: white;
   margin-bottom: 16px;
   font-weight: bold;
   font-size: 20px;
}
.logout-button{
   margin-top: 60px;
   display: flex;
   gap :10px;
   align-items: center;
}


@media screen and (max-width: 768px) {
   .Dashboard{
    flex-direction: column;
    position: relative;
   }
   .DashboardMenu{
    display: none;
   }
   .MobileMenu{
    display: block;
    position: absolute;
    top: 95px;
    background-color: var(--red-950);
    color:var(--red-300);
    padding:15px;
    width: 80%;
    min-height: 800px;
    z-index: 1000;
    border-top-right-radius: 40px;
   
   
   }
   .MobileMenu>ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:30px;
   }
   .MobileMenu>ul>li{
    width: 100%;
    display: flex;
    gap:15px;
    align-items: center;
   }
  }
  